import _ from 'lodash';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as api from './app.api';
import * as constants from './app.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as routeConstants from '../../constants/route.contants';
import * as appSagaUtils from './app.saga.utils';

function redirectToLegacyFunc(hub1Path, locationId, locationVersion) {
  let redirectUrl = `${hub1Path}/dashboard/#/dashboard`;
  if (locationId) {
    redirectUrl = `${redirectUrl}?location_id=${locationId}`;
    if (locationVersion) {
      redirectUrl = `${redirectUrl}&location_version=${locationVersion}`;
    }
  }
  window.location.replace(redirectUrl);
}

function* getApplicationData({ payload: hub1Path }) {
  try {
    const locationId = queryStringUtils.getLocationId(location.search);
    if (!locationId) {
      redirectToLegacyFunc(hub1Path);
      return;
    }
    const data = yield call(api.getApplicationData);
    if (_.isNil(data)) {
      redirectToLegacyFunc(hub1Path);
      return;
    }
    const { redirectToLegacy, locationVersion, locationRegionInfo } = data;
    if (redirectToLegacy) {
      redirectToLegacyFunc(hub1Path, locationId, locationVersion);
      return;
    }

    if (data.business.locationId.toString() !== locationId) {
      location.reload();
      return;
    }

    const userSelectedLang = constants.LANGUAGE_CODES[data.user.lang];
    if (userSelectedLang && userSelectedLang !== constants.DEFAULT_LOCALE) {
      const texts = yield call(api.loadTranslationsFetch, userSelectedLang);
      const ssrTexts = yield call(api.loadSsrTranslationsFetch, userSelectedLang);
      const legalSettings = yield call(api.getLegalSettings, locationRegionInfo.countryCode);
      yield put({ type: constants.CHANGE_LOCALE_SUCCESS, userSelectedLang, data: texts, ssrData: ssrTexts, legalSettings });
    }
    if (!window.location.origin.includes('qa') && !window.location.origin.includes('local')) {
      const index = data.languages.findIndex((lang) => lang.value === 'numeric');
      if (index !== -1) {
        data.languages.splice(index, 1);
      }
    }
    yield put({ type: constants.GET_APPLICATION_DATA_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_APPLICATION_DATA_FAIL });
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getApplicationData.Fail'] });
  }
}

function* getFranchisesData() {
  try {
    const data = yield call(api.getFranchisesData);
    yield put({ type: constants.GET_FRANCHISES_DATA_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getFranchisesData.Fail'] });
  }
}

function* contactSupport(payload) {
  try {
    const data = yield call(api.contactSupport, payload.data);
    yield put({ type: constants.CONTACT_SUPPORT_SUCCESS, data });
  } catch (error) {
    yield put({ type: constants.CONTACT_SUPPORT_FAIL, error });
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['contactSupport.Fail'] });
  }
}

function* getMonthlyCommunicationsLimitation() {
  try {
    const data = yield call(api.getMonthlyCommunicationsLimitation);
    yield put({ type: constants.GET_MONTHLY_COMMUNICATIONS_LIMITATION_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getMonthlyCommunicationsLimitation.Fail'] });
  }
}

function* getBusinessById({ payload: { hub1Path, businessId } }) {
  try {
    if (location.search.locationId !== businessId) {
      const newQueryString = `?location_id=${businessId}`;
      yield put(push({
        pathname: '/businessCenter',
        search: newQueryString,
      }));
    }
    const data = yield call(api.getBusinessData);
    if (data.redirectToLegacy) {
      yield redirectToLegacyFunc(hub1Path, businessId, data.locationVersion);
    }
    yield put({ type: constants.GET_APPLICATION_DATA, payload: hub1Path });
    yield put({ type: constants.GET_FRANCHISES_DATA });
    yield put({ type: constants.GET_BUSINESS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBusinessById.Fail'] });
  }
}

function* goToPage({ pageName }) {
  if (pageName === routeConstants.WELCOME_ROUTE) {
    return;
  }
  yield put(push({
    pathname: pageName,
    search: location.search,
  }));
}

function* goToWelcomePage() {
  yield put(push({
    pathname: routeConstants.WELCOME_ROUTE,
    search: location.search,
  }));
}

function* changeLocale({ locale, countryCode }) {
  try {
    const data = yield call(api.loadTranslationsFetch, locale);
    const ssrData = yield call(api.loadSsrTranslationsFetch, locale);
    const legalSettings = yield call(api.getLegalSettings, countryCode);
    yield put({ type: constants.CHANGE_LOCALE_SUCCESS, locale, data, ssrData, legalSettings });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['loadTranslations.Fail'] });
  }
}

function* userLogout({ payload: hub1Path }) {
  try {
    const data = yield call(api.userLogoutRequest, hub1Path);
    yield put({ type: constants.USER_LOGOUT_SUCCESS, hub1Path, hub2Path: data ? data.redirectUrl : '' });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['userLogoutRequest.Fail'] });
  }
}


function* getLocations() {
  try {
    const data = yield call(api.getLocations);
    yield put({ type: constants.GET_LOCATIONS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getCampaigns.Fail'] });
  }
}

function* getFeatures() {
  try {
    const data = yield call(api.getFeatures);
    yield put({ type: constants.GET_FEATURES_SUCCESS, data });
    yield put({ type: constants.CHECK_PAGE_PERMISSIONS, data });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getFeatures.Fail'] });
    }
  }
}

function* getCustomer({ search }) {
  if (!search) {
    return;
  }

  const searchTrimmed = search.trim();
  yield put({ type: constants.SET_CUSTOMER_DATA_LOADING, data: true });
  const type = appSagaUtils.getIdentifierType(searchTrimmed);

  if (type === constants.CUSTOMER_IDENTIFIER_KEY) {
    const keyIsLegal = yield call(api.checkMembershipKey, { membershipKey: searchTrimmed });
    if (!keyIsLegal) {
      yield put({
        type: errorConstant.HANDLE_ERROR,
        [errorConstant.MESSAGES_KEYS]: ['getCustomer.IllegalInput.notFound', 'getCustomer.IllegalInput.notFound.subtext'],
        [errorConstant.ERROR_TYPE]: errorConstant.ERROR_TYPE_CUSTOMER
      });
      yield put({ type: constants.SET_CUSTOMER_DATA_LOADING, data: false });
      yield put({ type: constants.GO_TO_WELCOME_PAGE });
      return;
    }
  }
  try {
    const customerResultList = yield call(api.queryCustomers, { type, search: searchTrimmed });
    const customerResult = customerResultList[0];
    const locationSearchObject = queryStringUtils.parse(location.search);
    locationSearchObject.search = customerResult;
    yield put(push({
      pathname: routeConstants.CUSTOMER_ROUTE,
      search: queryStringUtils.stringify(locationSearchObject),
    }));
    yield put({ type: constants.SET_CUSTOMER_MEMBERSHIP_KEY, data: customerResult });
    yield put({ type: constants.SET_CUSTOMER_DATA_LOADING, data: false });

  } catch (e) {
    const msg = `getCustomer.IllegalInput.${e.response.data === 'notFound' || e.response.data === 'notUnique' ? e.response.data : 'errorOccurred'}`;
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: [msg, 'getCustomer.IllegalInput.notFound.subtext'],
      [errorConstant.ERROR_TYPE]: errorConstant.ERROR_TYPE_CUSTOMER
    });
    yield put({ type: constants.SET_CUSTOMER_DATA_LOADING, data: false });
    yield put({ type: constants.GO_TO_WELCOME_PAGE });
  }
}

function* checkPagePermissions(action) {
  const userFeatures = action.data;
  const featureNamesPerPage = appSagaUtils.GetFeaturesByPage(location.pathname);
  const userFeaturesPerPage = userFeatures.filter((userFeature) => featureNamesPerPage.includes(userFeature[constants.FEATURE_NAME]));

  if (featureNamesPerPage.indexOf(constants.FEATURE_NAME_DATA_EXPORT) !== -1 || featureNamesPerPage.indexOf(constants.FEATURE_NAME_CAMPAIGN_CENTER) !== -1) {
    // temporary skip this check for permissions set
    // Campaign Center - NONE + Actions on Bulk of Member = FULL
    // Data Export - NONE
    return;
  }

  if (featureNamesPerPage.indexOf(constants.FEATURE_NAME_FILTER) !== -1) {
    if (userFeaturesPerPage.find((feature) => feature[constants.FEATURE_LEVEL] === constants.FEATURE_LEVEL_NONE)) {
      yield put(push({
        pathname: '/forbidden',
        search: location.search,
      }));
      return;
    }
    return;
  }

  if (userFeaturesPerPage.length > 0 && !userFeaturesPerPage.find((feature) => feature[constants.FEATURE_LEVEL] === constants.FEATURE_LEVEL_FULL)) {
    yield put(push({
      pathname: '/forbidden',
      search: location.search,
    }));
  }
}

function* updateUser({ user, countryCode }) {
  try {
    const data = yield call(api.updateUser, user);
    yield put({ type: constants.USER_UPDATE_SUCCESS, data });
    yield put({ type: constants.USER_UPDATE_EMAIL, data: data.email });

    const userSelectedLang = constants.LANGUAGE_CODES[data.lang];
    const texts = yield call(api.loadTranslationsFetch, userSelectedLang);
    const ssrTexts = yield call(api.loadSsrTranslationsFetch, data.lang);
    const legalSettings = yield call(api.getLegalSettings, countryCode);
    yield put({ type: constants.CHANGE_LOCALE_SUCCESS, userSelectedLang, data: texts, ssrData: ssrTexts, legalSettings });
    yield put({ type: constants.USER_UPDATE_LANG, data: data.lang });

  } catch (err) {
    yield put({ type: constants.USER_UPDATE_ERROR, [errorConstant.ERROR_TYPE_USER]: ['updateUser.Fail'] });
  }
}

function* setDemoMode({ demoMode }) {
  try {
    const data = yield call(api.setDemoMode, demoMode);
    yield put({ type: constants.SET_DEMO_MODE_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.SET_DEMO_MODE_ERROR, [errorConstant.ERROR_TYPE_USER]: ['setDemoMode.Fail'] });
  }
}

function* getVerificationCode(params) {
  try {
    const data = yield call(api.getVerificationCode, params.data);
    yield put({ type: constants.GET_VERIFICATION_CODE_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_VERIFICATION_CODE_ERROR, [errorConstant.ERROR_TYPE_USER]: ['updateUser.Fail'] });
  }
}

function* getAppClientId(params) {
  try {
    const data = yield call(api.getAppClientId, params.data);
    yield put({ type: constants.GET_APP_CLIENT_ID_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_APP_CLIENT_ID_ERROR, [errorConstant.ERROR_TYPE_USER]: ['updateUser.Fail'] });
  }
}

function* getRegistrationFields() {
  try {
    const data = yield call(api.getRegistrationFields);
    yield put({ type: constants.GET_REGISTRATION_FIELDS_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.GET_REGISTRATION_FIELDS_ERROR, [errorConstant.ERROR_TYPE_USER]: ['updateUser.Fail'] });
  }
}

function* createMember(params) {
  try {
    const data = yield call(api.createMember, params.data);
    yield put({ type: constants.CREATE_MEMBER_SUCCESS, data });
  } catch (err) {
    yield put({ type: constants.CREATE_MEMBER_ERROR, [errorConstant.ERROR_TYPE_USER]: ['updateUser.Fail'] });
  }
}

function* changeUserPassword(params) {
  try {
    const result = yield call(api.changeUserPassword, params.data);
    yield put({ type: constants.CHANGE_PASSWORD_SUCCESS, result });
  } catch (err) {
    yield put({ type: constants.CHANGE_PASSWORD_ERROR, err });
  }
}

function* cancel3rdPartyCode(params) {
  try {
    const data = yield call(api.cancel3rdPartyCode, params.codesArray);
    yield put({ type: constants.CANCEL_3RD_PARTY_CODE_SUCCESS, data });
  } catch (error) {
    yield put({ type: constants.CANCEL_3RD_PARTY_CODE_FAIL, error });
  }
}

function* getCurrentSMSProviders(params) {
  try {
    const data = yield call(api.getCurrentSMSProviders, params.countryCode);
    yield put({ type: constants.GET_CURRENT_SMS_PROVIDERS_SUCCESS, data });
  } catch (error) {
    yield put({ type: constants.GET_CURRENT_SMS_PROVIDERS_ERROR, error });
  }
}

function* getBusinesses() {
  try {
    const data = yield call(api.getBusinessesData);
    yield put({ type: constants.GET_BUSINESSES_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getBusinesses.Fail'] });
  }
}
function* getTags() {
  try {
    const data = yield call(api.getTags);
    yield put({ type: constants.GET_TAGS_SUCCESS, data });
  } catch (error) {
    console.warn('error while receiving tags');
  }
}

function* requestToUpgradeToProfessional(action) {
  yield call(api.requestToUpgradeToProfessional, action);
}

function* getBusinessTimeInfo() {
  try {
    const response = yield call(api.getBusinessTimeInfo);
    yield put({ type: constants.GET_BUSINESS_TIME_INFO_SUCCESS, response });
  } catch (error) {
    console.warn('error while receiving business date time');
  }

}

// sagas listen to dispatched actionsList too (same as reducers)
function* appSaga() {
  yield [
    takeEvery(constants.GET_APPLICATION_DATA, getApplicationData),
    takeEvery(constants.GET_FRANCHISES_DATA, getFranchisesData),
    takeEvery(constants.CONTACT_SUPPORT, contactSupport),
    takeEvery(constants.GET_MONTHLY_COMMUNICATIONS_LIMITATION, getMonthlyCommunicationsLimitation),
    takeEvery(constants.GET_BUSINESS, getBusinessById),
    takeLatest(constants.CHANGE_LOCALE, changeLocale),
    takeLatest(constants.USER_LOGOUT, userLogout),
    takeEvery(constants.GET_LOCATIONS, getLocations),
    takeEvery(constants.GET_CUSTOMER, getCustomer),
    takeLatest(constants.GET_FEATURES, getFeatures),
    takeLatest(constants.GO_TO_PAGE, goToPage),
    takeLatest(constants.GO_TO_WELCOME_PAGE, goToWelcomePage),
    takeLatest(constants.GET_TAGS, getTags),
    takeEvery(constants.CHECK_PAGE_PERMISSIONS, checkPagePermissions),
    takeEvery(constants.USER_UPDATE, updateUser),
    takeEvery(constants.GET_VERIFICATION_CODE, getVerificationCode),
    takeEvery(constants.GET_APP_CLIENT_ID, getAppClientId),
    takeEvery(constants.GET_REGISTRATION_FIELDS, getRegistrationFields),
    takeEvery(constants.CREATE_MEMBER, createMember),
    takeEvery(constants.CANCEL_3RD_PARTY_CODE, cancel3rdPartyCode),
    takeEvery(constants.GET_CURRENT_SMS_PROVIDERS, getCurrentSMSProviders),
    takeEvery(constants.CHANGE_USER_PASSWORD, changeUserPassword),
    takeEvery(constants.GET_BUSINESSES, getBusinesses),
    takeLatest(constants.REQUEST_TO_UPGRADE_TO_PROFESSIONAL, requestToUpgradeToProfessional),
    takeLatest(constants.GET_BUSINESS_TIME_INFO, getBusinessTimeInfo),
    takeLatest(constants.SET_DEMO_MODE, setDemoMode),
  ];
}

export default appSaga;
