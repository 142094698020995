// groups constants
export const APP_GROUP = 'app';
export const BENEFITS_GROUP = 'benefits';
export const FRIEND_REFERRAL = 'friend_referral';
export const COMMUNICATION_GROUP = 'communication';
export const CONNECTIVITY_GROUP = 'connectivity';
export const DATES_AND_TIMES_GROUP = 'datesAndTimes';
export const MEMBERSHIP_GROUP = 'membership';
export const PURCHASES_GROUP = 'purchases';
export const REFERRAL_GROUP = 'friendReferral';

// actions
export const ACTION_SUB_CATEGORY = 'subCategory';
export const TAG_MEMBERSHIP_ACTION = 'tagMembership';
export const DYNAMIC_TAG_MEMBERSHIP_ACTION = 'dynamicTagMembership';
export const TAG_MEMBERSHIP_TAG_FIELD = 'tag';
export const TAGS_TYPE = 'tagsType';
export const POINTS_TYPE = 'type';
export const TAGS_TYPE_FIXED = 'fixed';
export const SEND_PERSONAL_PUSH_ACTION = 'sendPersonalPush';
export const SEND_PERSONAL_PUSH_EXTRA_DATA = 'extraData';
export const SEND_PERSONAL_PUSH_EXTRA_DATA_KEY_FIELD = 'key';
export const SEND_PERSONAL_PUSH_EXTRA_DATA_VALUE_FIELD = 'value';
export const ASSIGN_ASSET_ACTION = 'assignAsset';
export const REMOVE_ASSET_ACTION = 'removeAsset';
export const ASSIGN_ASSET_BENEFIT_KEY_FIELD = 'benefitKey';
export const ASSIGN_ASSET_QUANTITY_FIELD = 'quantity';
export const AUTO_PUNCH_ACTION = 'punch';
export const AUTO_PUNCH_NUM_OF_PUNCHES = 'numberOfPunches';
export const PUNCH_EXCEEDED_ACTION = 'punchExceeded';
export const PUNCH_EXCEEDED_NUMBER_OF_PUNCHES_FIELD = 'numberOfExceededPunchesKey';
export const SEND_MEMBER_EMAIL_ACTION = 'sendMemberEmail';
export const UNSUBSCRIBE_EMAIL_OR_SMS = 'unsubscribeEmailOrSms';
export const UNSUBSCRIBE_TYPE = 'unsubscribeType';
export const SEND_MEMBER_EMAIL_KEY_FIELD = 'externalId';
export const SEND_MEMBER_EMAIL_TEMPLATE_NAME = 'name';
export const SEND_MEMBER_EMAIL_SELECTED_MEMBERS_COUNT = 'selectedMembersCount';
export const SEND_MEMBER_EMAIL_RULE_NAME = 'ruleName';
export const UPDATE_MEMBERSHIP_EXPIRATION_ACTION = 'updateMembershipExpiration';
export const UPDATE_MEMBERSHIP_FIELDS_ACTION = 'updateMembershipFields';
export const UPDATE_MEMBERSHIP_EXPIRATION_DURATION_VALUE_FIELD = 'durationValue';
export const UPDATE_MEMBERSHIP_EXPIRATION_DURATION_TYPE_FIELD = 'durationType';
export const ADD_POINTS_ACTION = 'addPoints';
export const REDEEM_POINTS_ACTION = 'redeemPoints';
export const DEDUCT_POINTS_ACTION = 'deductPoints';
export const DEDUCT_DYNAMIC_POINTS_ACTION = 'deductDynamicPoints';
export const ADD_DYNAMIC_POINTS_ACTION = 'addDynamicPoints';
export const TOP_UP_POINTS_ACTION = 'topUpPoints';
export const ACCUMULATE_POINTS_ACTION = 'accumulatePoints';
export const EXPORT_EVENT_ACTION = 'exportEvent';
export const WALLET_PASS_PUSH_NOTIFICATION_ACTION = 'walletPassPushNotification';
export const WALLET_PASS_SYNC_ACTION = 'walletPassSync';
export const EXPORT_EVENT_NAME_FIELD = 'name';
export const EXPORT_EVENT_HEADER_KEY_FIELD = 'key';
export const EXPORT_EVENT_HEADER_VALUE_FIELD = 'value';
export const EXPORT_EVENT_BODY_KEY_FIELD = 'key';
export const EXPORT_EVENT_BODY_VALUE_FIELD = 'value';
export const MEMBERSHIP_UPDATE_KEY_FIELD = 'key';
export const MEMBERSHIP_UPDATE_VALUE_FIELD = 'value';
export const EXPORT_EVENT_CUSTOM_HEADERS = 'customHeaders';
export const EXPORT_EVENT_CUSTOM_BODY_FIELDS = 'customBodyFields';
export const UPDATE_MEMBERSHIP_FIELD_LISTS = 'fieldsToUpdate';
export const UPDATE_MEMBERSHIP_FIELD_VALUE_LIST = 'updateMembershipFieldValueList';
export const EXPORT_EVENT_DESTINATION_FIELD = 'destination';
export const EXPORT_EVENT_EXTEND_MEMBERSHIP_DATA_FIELD = 'extendMembershipData';
export const SEND_MEMBER_SMS_ACTION = 'sendMemberSms';
export const UN_TAG_MEMBERSHIP_ACTION = 'untagMembership';
export const DYNAMIC_UN_TAG_MEMBERSHIP_ACTION = 'dynamicUntagMembership';
export const UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION = 'updateMembershipSensitiveData';
export const UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE = 'fieldType';
export const UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD = 'allowSms';
export const UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD = 'allowEmail';
export const UPDATE_MEMBERSHIP_SENSITIVE_DATA_GIVE_CONSENT_TYPE_FIELD = 'GiveConsent';
export const WALLET_PASS_NOTIFICATION_MESSAGE = 'notificationMessage';
export const DELETE_MEMBERSHIP_ACTION = 'deleteMembership';
export const SEND_MESSAGE_ACTION = 'sendMessage';
export const REGISTER_MEMBERSHIP_ACTION = 'registerMembership';
export const SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION = 'sendTcConsentSmsOrEmail';

export const DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION = 'discountAmountOffOnEntireTicket';
export const DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION = 'discountPercentOffOnEntireTicket';
export const SEND_CODES_TO_POS = 'sendCodesToPos';
export const NO_POS_GIFT = 'noPosGift';

export const DISCOUNT_SPECIAL_PRICE_ACTION = 'discountSpecialPrice';
export const DISCOUNT_FREE_ITEMS_ACTION = 'discountFreeItems';
export const DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION = 'discountAmountOffOnSpecificItems';
export const DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION = 'discountPercentOffOnSpecificItems';

export const DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION = 'discountSpecialPriceAdvanced';
export const DISCOUNT_FREE_ITEMS_ADVANCED_ACTION = 'discountFreeItemsAdvanced';
export const DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION = 'discountAmountOffOnSpecificItemsAdvanced';
export const DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION = 'discountPercentOffOnSpecificItemsAdvanced';

// VALUE TYPES
export const VALUE_TYPE_BEACON_SIGNALS_LIST = 'beaconSignalsList';
export const VALUE_TYPE_BENEFITS_LIST = 'benefitsList';
export const VALUE_TYPE_BOOLEAN = 'boolean';
export const VALUE_TYPE_CATALOG_ITEMS_LIST = 'catalogItemsList';
export const VALUE_TYPE_CURRENCY = 'currency';
export const VALUE_TYPE_PERCENTAGE_INTEGER = 'percentageInteger';
export const VALUE_TYPE_DATE = 'date';
export const VALUE_TYPE_DATE_TIME = 'dateTime';
export const VALUE_TYPE_DAYS_TIMES = 'daysTimes';
export const VALUE_TYPE_FORMS_LIST = 'formsList';
export const VALUE_TYPE_NUMBER = 'number';
export const VALUE_TYPE_PAYMENT_FLOW = 'paymentFlow';
export const VALUE_TYPE_PAYMENT_FLOW_IN_APP = 'paymentFlowInApp';
export const FILTER_VALUE_TYPE_PAYMENT_FLOW_IN_APP = 'filterPaymentFlowInApp';
export const VALUE_TYPE_PAYMENT_TYPE = 'paymentType';
export const VALUE_TYPE_POINTS = 'points';
export const VALUE_TYPE_PROVIDERS_LIST = 'providersList';
export const VALUE_TYPE_ASSET_SOURCE_LIST = 'assetSourceList';
export const VALUE_TYPE_STRING = 'string';
export const VALUE_TYPE_ACTIVITIES = 'scheduled_action_id';
export const VALUE_TYPE_MEMBERSHIP_STATUS_LIST = 'membershipStatusList';
export const VALUE_TYPE_MEMBERSHIP_CONSENT_LIST = 'membershipConsentList';
export const VALUE_TYPE_MEMBERSHIP_RFM_LIST = 'membershipRfmList';
export const VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST = 'membershipLastVisitList';
export const VALUE_TYPE_MONTHS_LIST = 'monthsList';
export const VALUE_TYPE_MONTHS_AND_DAYS_LIST = 'monthsAndDaysList';
export const VALUE_TYPE_DYNAMIC = 'dynamic';
export const VALUE_TYPE_ARRAY = 'array';
export const VALUE_TYPE_GAVE_CONSENT_SOURCE = 'source';
export const VALUE_TYPE_UPDATE_MEMBERSHIP_OPERATION = 'updateMembershipOperation';
export const VALUE_TYPE_JOINING_SOURCE = 'joiningSource';
export const VALUE_TYPE_REFERRAL_DATA = 'referralData';
export const VALUE_TYPE_DEFAULT_FIXED_HIDDEN_VALUE = 'defaultFixedHiddenValue';
export const VALUE_TYPE_LOCATIONS = 'locationsList';
export const VALUE_TYPE_SENT_FROM = 'sentFromList';
export const VALUE_TYPE_EMAIL_TEMPLATE = 'emailTemplateList';
export const VALUE_TYPE_AUTO_COMPLETE = 'autoCompleteList';

export const OPTIONS = 'options';

// CONDITIONS
export const CONTEXT_EVENT_TIME = 'context.EventTime';
export const CONTEXT_PURCHASE_TOTAL_SUM = 'context.Purchase.TotalSum';
export const CONTEXT_PURCHASE_TIMESTAMP = 'context.UnixTimeStamp';
export const CONTEXT_FRIEND_PURCHASE_TOTAL_SUM = 'context.friend_purchase.total_sum';
export const CONTEXT_PURCHASE_BRANCH_ID = 'context.Purchase.BranchID';
export const CONTEXT_LOCATIONS_GROUPS = 'context.Purchase.Locations.Groups';
export const CONTEXT_FAIL_SMS_STEP = 'context.Fail_sms.Step';
export const CONTEXT_FAIL_SMS_TYPE = 'context.Fail_sms.Type';
export const CONTEXT_PURCHASE_POS_ID = 'context.Purchase.PosID';
export const CONTEXT_PURCHASE_NUMBER_OF_MEMBERS = 'context.Purchase.NumOfMembers';
export const CONTEXT_PURCHASE_TAGS = 'context.Purchase.Tags';
export const CONTEXT_PURCHASE_TRANSACTION_SOURCE = 'context.Purchase.TransactionSource';
export const CONTEXT_PURCHASE_TRANSACTION_SOURCE_NAME = 'context.Purchase.TransactionSourceName';
export const CONTEXT_PURCHASE_CASHIER = 'context.Purchase.Cashier';
export const CONTEXT_ITEM_PRICE = 'context.ItemPrice';
export const CONTEXT_PAYMENT_FLOW = 'context.PaymentFlow';
export const CONTEXT_ACTUAL_CHARGE = 'context.ActualCharge';
export const CONTEXT_ASSET_TEMPLATE = 'context.Asset.Template';
export const CONTEXT_REDEEM_ASSET_TEMPLATE = 'redeem.asset.template';
export const CONTEXT_PUNCH_ASSET_TEMPLATE = 'punch.asset.template';
export const CONTEXT_ASSET_DATA_SOURCE = 'context.SourceDataSource';
export const CONTEXT_NUM_OF_PUNCHES = 'context.NumOfPunches';
export const CONTEXT_TOTAL_PUNCHES_AFTER_OPERATION = 'context.TotalPunchesAfterOperation';
export const CONTEXT_LAST_PUNCH = 'context.LastPunch';
export const CONTEXT_USED_REFERRAL_CODE = 'context.Referred';
export const CONTEXT_REFERRAL_DATA = 'context.ReferralData';
export const CONTEXT_JOIN_SOURCE = 'context.Source';
export const CONTEXT_DATA_AMOUNTS_POINTS = 'context.Data.Amounts.Points';
export const CONTEXT_DATA_AMOUNTS_BUDGET_WEIGHTED = 'context.Data.Amounts.BudgetWeighted';
export const CONTEXT_DATA_AMOUNTS_WEIGHTED_POINTS = 'context.Data.Amounts.WeightedPoints';
export const CONTEXT_DATA_NEW_POINTS_SET_POINTS = 'context.Data.NewPointsSet.Points';
export const CONTEXT_DATA_NEW_POINTS_SET_BUDGET_WEIGHTED = 'context.Data.NewPointsSet.BudgetWeighted';
export const CONTEXT_CATALOG_ITEM = 'context.Catalog.Item';
export const CONTEXT_TAG = 'context.Tag';
export const CONTEXT_FORM_ID = 'context.formId';
export const CONTEXT_DATA_SUB_TYPE = 'context.Data.SubType';
export const CONTEXT_DATA_DATA_UUID = 'context.Data.Data.uuid';
export const CONTEXT_DATA_DATA_MAJOR = 'context.Data.Data.major';
export const CONTEXT_DATA_DATA_MINOR = 'context.Data.Data.minor';
export const CONTEXT_DATA_TYPE = 'context.Data.Type';
export const CONTEXT_DATA_DATA_STRING_VALUE_1 = 'context.Data.Data.StringValue1';
export const CONTEXT_DATA_DATA_STRING_VALUE_2 = 'context.Data.Data.StringValue2';
export const CONTEXT_DATA_DATA_STRING_VALUE_3 = 'context.Data.Data.StringValue3';
export const CONTEXT_DATA_DATA_STRING_VALUE_4 = 'context.Data.Data.StringValue4';
export const CONTEXT_DATA_DATA_STRING_VALUE_5 = 'context.Data.Data.StringValue5';
export const CONTEXT_DATA_DATA_STRING_VALUE_6 = 'context.Data.Data.StringValue6';
export const CONTEXT_DATA_DATA_STRING_VALUE_7 = 'context.Data.Data.StringValue7';
export const CONTEXT_DATA_DATA_STRING_VALUE_8 = 'context.Data.Data.StringValue8';
export const CONTEXT_DATA_DATA_STRING_VALUE_9 = 'context.Data.Data.StringValue9';
export const CONTEXT_DATA_DATA_STRING_VALUE_10 = 'context.Data.Data.StringValue10';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_1 = 'context.Data.Data.NumericValue1';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_2 = 'context.Data.Data.NumericValue2';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_3 = 'context.Data.Data.NumericValue3';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_4 = 'context.Data.Data.NumericValue4';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_5 = 'context.Data.Data.NumericValue5';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_6 = 'context.Data.Data.NumericValue6';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_7 = 'context.Data.Data.NumericValue7';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_8 = 'context.Data.Data.NumericValue8';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_9 = 'context.Data.Data.NumericValue9';
export const CONTEXT_DATA_DATA_NUMERIC_VALUE_10 = 'context.Data.Data.NumericValue10';
export const CONTEXT_DATA_DATA_DATE_VALUE_1 = 'context.Data.Data.DateValue1';
export const CONTEXT_DATA_DATA_DATE_VALUE_2 = 'context.Data.Data.DateValue2';
export const CONTEXT_DATA_DATA_DATE_VALUE_3 = 'context.Data.Data.DateValue3';
export const CONTEXT_DATA_DATA_DATE_VALUE_4 = 'context.Data.Data.DateValue4';
export const CONTEXT_DATA_DATA_DATE_VALUE_5 = 'context.Data.Data.DateValue5';
export const CONTEXT_DATA_DATA_DATE_VALUE_6 = 'context.Data.Data.DateValue6';
export const CONTEXT_DATA_DATA_DATE_VALUE_7 = 'context.Data.Data.DateValue7';
export const CONTEXT_DATA_DATA_DATE_VALUE_8 = 'context.Data.Data.DateValue8';
export const CONTEXT_DATA_DATA_DATE_VALUE_9 = 'context.Data.Data.DateValue9';
export const CONTEXT_DATA_DATA_DATE_VALUE_10 = 'context.Data.Data.DateValue10';

// Registration form field generic field values
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_1 = 'context.Data.Data.BooleanValue1';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_2 = 'context.Data.Data.BooleanValue2';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_3 = 'context.Data.Data.BooleanValue3';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_4 = 'context.Data.Data.BooleanValue4';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_5 = 'context.Data.Data.BooleanValue5';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_6 = 'context.Data.Data.BooleanValue6';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_7 = 'context.Data.Data.BooleanValue7';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_8 = 'context.Data.Data.BooleanValue8';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_9 = 'context.Data.Data.BooleanValue9';
export const CONTEXT_DATA_DATA_BOOLEAN_VALUE_10 = 'context.Data.Data.BooleanValue10';

export const CONTEXT_SHOPPING_CART_TOTAL_QUANTITY = 'shoppingCart.TotalQuantity';
export const CONTEXT_SHOPPING_CART_TOTAL_PRICE = 'shoppingCart.TotalPrice';
export const USER_ACTIONS_QUANTITY = 'resultsNum';
export const USER_ACTIONS_NUM_OF_PURCHASES = 'num.of.purchases';
export const TOTAL_SUM_OF_ALL_PURCHASES = 'all.purchases.total.sum';
export const CONTEXT_PAYMENT_TYPES = 'context.PaymentTypes';
export const CONDITION_KEY_BRANCH_ID = 'branchId';
export const CONTEXT_TIMESTAMP = 'context.TimeStamp';
export const CONTEXT_TIMESTAMP_DAYS = 'context.TimeStampDays';
export const CONTEXT_AVAILABLE_PUNCH_CARD = 'context.AvailablePunchCard';
export const CONTEXT_NO_ACTIVE_FUTURE_INPROGRESS_PUNCHCARD = 'context.NoActiveFutureInProgressPunchCard';
export const CONTEXT_EXPIRED_PUNCH_CARD = 'context.ExpiredPunchCard';
export const CONTEXT_MEMBERSHIP_KEY_STR = 'context.MembershipKey';
export const MEMBERSHIP_KEYS_FILE = 'membershipKeysFile';
export const MEMBERSHIP_PHONENUMBER_KEYS_FILE = 'membershipPhoneNumberKeysFile';
export const EMAIL_RECEIVED_SUCCESSFULLY = 'email.receivedSuccessfully';
export const EMAIL_SENT_FROM = 'email.sentFrom';
export const EMAIL_TEMPLATE = 'email.template';
export const EMAIL_OPENED = 'email.opened';
export const EMAIL_CLICKED = 'email.clicked';
export const PROBABILITY = 'probability';
export const PROVIDER_TYPE = 'context.ProviderType';
export const ACTIVITIES = 'activitiesList';
export const CASES_ONLY_CONDITION = 'casesOnlyCondition';

export const CONDITIONS_PER_ACTION = 'conditionsPerAction';
export const AGGREGATED_CONDITIONS_PER_ACTION = 'aggregatedConditionsPerAction';

export const CONDITION_KEY_SHOPPING_CART_CERTAIN_ITEMS = 'shoppingCart.certainItems';

export const DATETIME_GLOBAL_CONDITION = 'datetimeGlobalCondition';
export const MEMBERSHIP_GLOBAL_CONDITION = 'membershipGlobalCondition';
export const OCCURRENCES_GLOBAL_CONDITION = 'occurrencesGlobalCondition';

export const REMOVED_GLOBAL_CONDITIONS_PER_TRIGGER = 'removedGlobalConditionsPerTrigger';

export const ITEM_CODE = 'item.ItemCode';
/*
export const FRIEND_PURCHASE_ITEM_CODE = 'friendPurchase.item.ItemCode';
*/
export const ITEM_CODE_IPN = 'context.ItemCode';
export const ITEM_PRICE = 'item.Price';
export const ITEM_DEPARTMENT_CODE = 'item.DepartmentCode';
export const ITEM_DEPARTMENT_NAME = 'item.DepartmentName';
export const ITEM_NAME = 'item.ItemName';
export const ITEM_TAGS = 'item.Tags';
export const ITEMS_GROUP = 'itemsGroup';
export const CONTEXT_SOURCE_DATA_BENEFITS_PRICE = 'context.Price';
export const CONTEXT_PURCHASE_ORDER_TYPE = 'context.Purchase.OrderType';
export const CONTEXT_GAVE_CONSENT_SOURCE = 'context.Source';
export const CODE_TAG = 'context.UpdatedFields.CodeTag[0].NewValue';
export const OPERATION = 'context.Operation';
export const GAVE_CONSENT_FROM_APP = 'App';
export const GAVE_CONSENT_FROM_CUSTOMER_PORTAL = 'CustomerPortal';
export const GAVE_CONSENT_FROM_LANDING_PAGE = 'LandingPage';
export const GAVE_CONSENT_FROM_HUB = 'Hub';

// LOCATIONS CONDITIONS
export const LOCATIONS_TAG_PREFERRED = 'Personal|Preferred Location|';
export const LOCATIONS_TAG_SECOND_PREFERRED = 'Personal|2nd Preferred Location|';
export const LOCATIONS_TAG_BORDER = '*';

export const RFM_TAGS = {
  'CHAMPION': '*Behavior|RFM|Champion*',
  'LOYAL': '*Behavior|RFM|Loyal*',
  'ALMOST.LOYAL': '*Behavior|RFM|Almost Loyal*',
  'OCCASIONAL': '*Behavior|RFM|Occasional*',
  'BIG.SPENDERS': '*Behavior|RFM|Big Spenders*',
  'BIG.SPENDERS.AT.RISK': '*Behavior|RFM|Big Spenders At Risk*',
  'ALMOST.LOST': '*Behavior|RFM|Almost Lost*',
  'LOST': '*Behavior|RFM|Lost*',
  'NEW': '*Behavior|RFM|New*',
};

export const TIME_SINCE_LAST_VISIT_TAGS = {
  '0.TO.6.DAYS': '*Behavior|Days Since Last Visit|0-6*',
  '7.TO.13.DAYS': '*Behavior|Days Since Last Visit|7-13*',
  '14.TO.29.DAYS': '*Behavior|Days Since Last Visit|14-29*',
  '1.MONTH': '*Behavior|Days Since Last Visit|1 Month*',
  '2.MONTHS': '*Behavior|Days Since Last Visit|2 Months*',
  '3.MONTHS': '*Behavior|Days Since Last Visit|3 Months*',
  '4.MONTHS': '*Behavior|Days Since Last Visit|4 Months*',
  '5.MONTHS': '*Behavior|Days Since Last Visit|5 Months*',
  '6.MONTHS': '*Behavior|Days Since Last Visit|6 Months*',
  '7.MONTHS': '*Behavior|Days Since Last Visit|7 Months*',
  '8.MONTHS': '*Behavior|Days Since Last Visit|8 Months*',
  '9.MONTHS.PLUS': '*Behavior|Days Since Last Visit|9 Months Plus*',
  'YEAR.PLUS': '*Behavior|Days Since Last Visit|Year Plus*',
  'NEVER.VISITED': '*Behavior|Days Since Last Visit|Never Visited*',
};

export const MONTHS = {
  'JANUARY': '01',
  'FEBRUARY': '02',
  'MARCH': '03',
  'APRIL': '04',
  'MAY': '05',
  'JUNE': '06',
  'JULY': '07',
  'AUGUST': '08',
  'SEPTEMBER': '09',
  'OCTOBER': '10',
  'NOVEMBER': '11',
  'DECEMBER': '12'
};
export const DAYS_BY_MONTH = {
  '01': 31,
  '02': 29,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31
};
// Join club source
export const JOIN_CLUB_FROM_APP = 'App';
export const JOIN_CLUB_FROM_POS = 'POS';
export const JOIN_CLUB_FROM_IMPORT = 'Import';
export const JOIN_CLUB_FROM_QUICK_REGISTER = 'QuickRegistration';

export const JOIN_CLUB_FROM_CUSTOMER_PORTAL = 'CustomerPortal';
export const JOIN_CLUB_FROM_QUICK_REGISTER_CODE = 'quickRegistrationCode';
export const JOIN_CLUB_FROM_CLIENT = 'Client';
export const JOIN_CLUB_FROM_AUTOMATION = 'Automation';
export const JOIN_CLUB_FROM_HUB = 'Hub';

// Communication email source
export const COMMUNICATION_FROM_POS = 'POS';
export const COMMUNICATION_FROM_AUTOMATION = 'Automation';
export const COMMUNICATION_FROM_HUB = 'Hub';
export const COMMUNICATION_FROM_CLIENT = 'Client';
export const COMMUNICATION_FROM_ADMIN = 'Admin';
export const COMMUNICATION_FROM_SERVER = 'Server';

// Communication sms types and steps
export const COMMUNICATION_MARKETING_SMS = 'marketing';
export const COMMUNICATION_OPERATIVE_SMS = 'operative';
export const COMMUNICATION_SMS_SERVER_STEP = 'server';
export const COMMUNICATION_SMS_SMS_GW_STEP = 'smsGateway';
export const COMMUNICATION_SMS_PROVIDER_STEP = 'provider';
export const COMMUNICATION_SMS_CARRIER_STEP = 'carrier';


// MEMBERSHIP
export const CONTEXT_SHARE_BUDGET_RECEIVER = 'context.Data.receiver';
export const CONTEXT_SHARE_BUDGET_SENDER = 'context.Data.sender';
export const CONTEXT_SHARE_BUDGET_AMOUNT = 'context.Data.amount';
export const CONTEXT_SHARE_BUDGET_TYPE = 'context.Data.wallet';
export const CONTEXT_SHARE_BUDGET_LINK = 'context.Data.budgetTransferLink';
export const CONTEXT_MEMBERSHIP_ADDRESS_CITY = 'context.Membership.AddressCity';
export const CONTEXT_MEMBERSHIP_ADDRESS_COUNTRY = 'context.Membership.AddressCountry';
export const CONTEXT_MEMBERSHIP_ADDRESS_FLOOR = 'context.Membership.AddressFloor';
export const CONTEXT_MEMBERSHIP_ADDRESS_HOME = 'context.Membership.AddressHome';
export const CONTEXT_MEMBERSHIP_ADDRESS_LINE_1 = 'context.Membership.AddressLine1';
export const CONTEXT_MEMBERSHIP_ADDRESS_LINE_2 = 'context.Membership.AddressLine2';
export const CONTEXT_MEMBERSHIP_ADDRESS_STATE = 'context.Membership.AddressState';
export const CONTEXT_MEMBERSHIP_ADDRESS_STREET = 'context.Membership.AddressStreet';
export const CONTEXT_MEMBERSHIP_ADDRESS_ZIP_CODE = 'context.Membership.AddressZipCode';
export const CONTEXT_MEMBERSHIP_ALLOW_EMAIL = 'context.Membership.AllowEmail';
export const CONTEXT_MEMBERSHIP_ALLOW_SMS = 'context.Membership.AllowSMS';
export const CONTEXT_MEMBERSHIP_ANNIVERSARY_MONTH = 'context.Membership.AnniversaryMonth';
export const CONTEXT_MEMBERSHIP_COMO_MEMBER_ID = 'context.Membership.comoMemberID';
export const CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH = 'context.Membership.BirthdayMonth';
export const CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH_AND_DAY = 'context.Membership.BirthdayMonthAndDay';
export const CONTEXT_MEMBERSHIP_BUDGETWEIGHTED = 'context.Membership.BudgetWeighted';
export const CONTEXT_MEMBERSHIP_EXPIRATION_DATE = 'context.Membership.ExpirationDate';
export const CONTEXT_MEMBERSHIP_EXT_CLUB_MEMBER_ID = 'context.Membership.ExtClubMemberID';
export const CONTEXT_MEMBERSHIP_COMMON_EXT_ID = 'context.Membership.CommonExtID';
export const CONTEXT_MEMBERSHIP_FAVORITE_BRANCH_ID = 'context.Membership.FavoriteBranchID';
export const CONTEXT_MEMBERSHIP_FIRST_NAME_CI = 'caseInsensitive.context.Membership.FirstName';
export const CONTEXT_MEMBERSHIP_GENDER = 'context.Membership.Gender';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_1 = 'context.Membership.GenericString1';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_2 = 'context.Membership.GenericString2';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_3 = 'context.Membership.GenericString3';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_4 = 'context.Membership.GenericString4';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_5 = 'context.Membership.GenericString5';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_6 = 'context.Membership.GenericString6';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_7 = 'context.Membership.GenericString7';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_8 = 'context.Membership.GenericString8';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_9 = 'context.Membership.GenericString9';
export const CONTEXT_MEMBERSHIP_GENERIC_STRING_10 = 'context.Membership.GenericString10';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_1 = 'context.Membership.GenericInteger1';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_2 = 'context.Membership.GenericInteger2';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_3 = 'context.Membership.GenericInteger3';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_4 = 'context.Membership.GenericInteger4';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_5 = 'context.Membership.GenericInteger5';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_6 = 'context.Membership.GenericInteger6';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_7 = 'context.Membership.GenericInteger7';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_8 = 'context.Membership.GenericInteger8';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_9 = 'context.Membership.GenericInteger9';
export const CONTEXT_MEMBERSHIP_GENERIC_INTEGER_10 = 'context.Membership.GenericInteger10';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_1 = 'context.Membership.GenericDate1';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_2 = 'context.Membership.GenericDate2';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_3 = 'context.Membership.GenericDate3';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_4 = 'context.Membership.GenericDate4';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_5 = 'context.Membership.GenericDate5';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_6 = 'context.Membership.GenericDate6';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_7 = 'context.Membership.GenericDate7';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_8 = 'context.Membership.GenericDate8';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_9 = 'context.Membership.GenericDate9';
export const CONTEXT_MEMBERSHIP_GENERIC_DATE_10 = 'context.Membership.GenericDate10';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_1 = 'context.Membership.GenericCheckBox1';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_2 = 'context.Membership.GenericCheckBox2';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_3 = 'context.Membership.GenericCheckBox3';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_4 = 'context.Membership.GenericCheckBox4';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_5 = 'context.Membership.GenericCheckBox5';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_6 = 'context.Membership.GenericCheckBox6';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_7 = 'context.Membership.GenericCheckBox7';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_8 = 'context.Membership.GenericCheckBox8';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_9 = 'context.Membership.GenericCheckBox9';
export const CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_10 = 'context.Membership.GenericCheckBox10';

// Registration form generic fields
export const GENERIC_CHECKBOX_1 = 'GenericCheckBox1';
export const GENERIC_CHECKBOX_2 = 'GenericCheckBox2';
export const GENERIC_CHECKBOX_3 = 'GenericCheckBox3';
export const GENERIC_CHECKBOX_4 = 'GenericCheckBox4';
export const GENERIC_CHECKBOX_5 = 'GenericCheckBox5';
export const GENERIC_CHECKBOX_6 = 'GenericCheckBox6';
export const GENERIC_CHECKBOX_7 = 'GenericCheckBox7';
export const GENERIC_CHECKBOX_8 = 'GenericCheckBox8';
export const GENERIC_CHECKBOX_9 = 'GenericCheckBox9';
export const GENERIC_CHECKBOX_10 = 'GenericCheckBox10';
export const GENERIC_DATE_1 = 'GenericDate1';
export const GENERIC_DATE_2 = 'GenericDate2';
export const GENERIC_DATE_3 = 'GenericDate3';
export const GENERIC_DATE_4 = 'GenericDate4';
export const GENERIC_DATE_5 = 'GenericDate5';
export const GENERIC_DATE_6 = 'GenericDate6';
export const GENERIC_DATE_7 = 'GenericDate7';
export const GENERIC_DATE_8 = 'GenericDate8';
export const GENERIC_DATE_9 = 'GenericDate9';
export const GENERIC_DATE_10 = 'GenericDate10';
export const GENERIC_INTEGER_1 = 'GenericInteger1';
export const GENERIC_INTEGER_2 = 'GenericInteger2';
export const GENERIC_INTEGER_3 = 'GenericInteger3';
export const GENERIC_INTEGER_4 = 'GenericInteger4';
export const GENERIC_INTEGER_5 = 'GenericInteger5';
export const GENERIC_INTEGER_6 = 'GenericInteger6';
export const GENERIC_INTEGER_7 = 'GenericInteger7';
export const GENERIC_INTEGER_8 = 'GenericInteger8';
export const GENERIC_INTEGER_9 = 'GenericInteger9';
export const GENERIC_INTEGER_10 = 'GenericInteger10';
export const GENERIC_STRING_1 = 'GenericString1';
export const GENERIC_STRING_2 = 'GenericString2';
export const GENERIC_STRING_3 = 'GenericString3';
export const GENERIC_STRING_4 = 'GenericString4';
export const GENERIC_STRING_5 = 'GenericString5';
export const GENERIC_STRING_6 = 'GenericString6';
export const GENERIC_STRING_7 = 'GenericString7';
export const GENERIC_STRING_8 = 'GenericString8';
export const GENERIC_STRING_9 = 'GenericString9';
export const GENERIC_STRING_10 = 'GenericString10';

export const CONTEXT_MEMBERSHIP_COMO_MEMBER_ID_FOR_EMAIL_ACTION = 'context.Membership.ComoMemberID';
export const CONTEXT_MEMBERSHIP_MEMBER_ID = 'context.Membership.MemberID';
export const CONTEXT_MEMBERSHIP_FIRST_NAME = 'context.Membership.FirstName';
export const CONTEXT_MEMBERSHIP_LAST_NAME = 'context.Membership.LastName';
export const CONTEXT_MEMBERSHIP_EMAIL = 'context.Membership.Email';
export const CONTEXT_MEMBERSHIP_BIRTHDAY = 'context.Membership.Birthday';
export const CONTEXT_MEMBERSHIP_ANNIVERSARY = 'context.Membership.Anniversary';
export const CONTEXT_MEMBERSHIP_TERMS_OF_USE_DATE = 'context.Membership.TermsOfUseDate';
export const CONTEXT_MEMBERSHIP_GOV_ID = 'context.Membership.GovID';
export const CONTEXT_MEMBERSHIP_HAS_CONSENT = 'context.Membership.ConsentForHub';
export const CONTEXT_MEMBERSHIP_HOME_BRANCH_ID = 'context.Membership.HomeBranchID';
export const PREFERRED_LOCATION = 'preferredLocation';
export const CONTEXT_MEMBERSHIP_LAST_NAME_CI = 'caseInsensitive.context.Membership.LastName';
export const CONTEXT_MEMBERSHIP_LAST_VISIT = 'context.Membership.MembershipLastVisitTag';
export const CONTEXT_MEMBERSHIP_LOCATION_ENABLED = 'context.Membership.LocationEnabled';
export const CONTEXT_MEMBERSHIP_MOBILE_APP_USED = 'context.Membership.MobileAppUsed';
export const CONTEXT_MEMBERSHIP_PHONE_NUMBER = 'context.Membership.PhoneNumber';
export const CONTEXT_MEMBERSHIP_POINTS = 'context.Membership.Points';
export const CONTEXT_MEMBERSHIP_ACCUMULATED_POINTS = 'context.Membership.AccumulatedPoints';
export const CONTEXT_MEMBERSHIP_ACCUMULATED_BUDGET_WEIGHTED = 'context.Membership.BudgetAccumulatedWeighted';
export const CONTEXT_MEMBERSHIP_PREFERRED_LOCATION = 'context.Membership.MembershipPreferredLocationTag';
export const CONTEXT_MEMBERSHIP_PREFERRED_LOCATION_GROUP = 'context.Membership.MembershipPreferredLocationsGroup';
export const CONTEXT_MEMBERSHIP_PUSH_NOTIFICATION_ENABLED = 'context.Membership.PushNotificationEnabled';
export const CONTEXT_MEMBERSHIP_RFM = 'context.Membership.MembershipRfmTag';
export const CONTEXT_MEMBERSHIP_SECOND_PREFERRED_LOCATION = 'context.Membership.MembershipSecondPreferredLocationTag';
export const CONTEXT_MEMBERSHIP_STATUS = 'context.Membership.MembershipStatus';
export const CONTEXT_MEMBERSHIP_STORED_CREDIT_CARD = 'context.Membership.StoredCreditCard';
export const CONTEXT_MEMBERSHIP_TAG = 'context.Membership.Tag';

export const MEMBERSHIP_STATUS_VALUE_REGISTERED = 'registered';
export const MEMBERSHIP_STATUS_VALUE_UNREGISTERED = 'not_registered';
export const MEMBERSHIP_STATUS_VALUE_PENDING = 'pending';
export const MEMBERSHIP_CONSENT_VALUE_YES = 'yes';
export const MEMBERSHIP_CONSENT_VALUE_NO = 'no';
export const MEMBERSHIP_CONSENT_VALUE_BUSINESS_APPROVAL = 'previous';

// Data types
export const DATA_VALUE_TYPE_INTEGER = 'integer';
export const DATA_VALUE_TYPE_STRING = 'string';
export const DATA_VALUE_TYPE_STRING_ARRAY = 'stringArray';
export const DATA_VALUE_TYPE_LOCATIONS_GROUPS = 'locationsGroups';
export const MONTH_AND_DAY = 'monthsAndDays';
export const MONTH = 'months';
export const DATA_VALUE_TYPE_FILE_LINK = 'fileLink';
export const DATA_VALUE_TYPE_BOOLEAN = 'boolean';
export const DATA_VALUE_TYPE_DATE_TIME = 'dateTime';
export const DATA_VALUE_TYPE_DATE = 'date';
export const DATA_VALUE_TYPE_DATE_TIME_BETWEEN = 'dateTimeBetween';
export const DATA_VALUE_TYPE_DAYS_OF_WEEK_RANGE_CONDITION = 'daysOfWeekRangeCondition';
export const DATA_VALUE_TYPE_MEMBER_FIELD_CHANGED_FROM_TO = 'memberFieldChangeFromTo';
export const DATA_VALUE_BOOL_STRING_ARRAY = 'boolStringArray';

// OPERATORS
export const OPERATORS_IS_ANY_VALUE = 'anyValue';
export const OPERATORS_IS_EMPTY = 'noValue';
export const OPERATORS_SPECIFIC_VALUE = 'specificValue';
export const OPERATORS_UPDATE_MEMBERSHIP = 'memberFieldChangeFromTo';

// TRIGGERS
export const NO_TRIGGER = '';
export const TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED = 'appCreditCardPaymentIsDeclined';
export const TRIGGER_BEACON_SIGNAL = 'beaconSignal';
export const TRIGGER_NO_TRIGGER = 'null';
export const TRIGGER_CLAIM = 'claim';
export const TRIGGER_CODE_GENERATOR = 'codeGenerator';
export const TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS = 'consentedToTermsAndCodnitions';
export const TRIGGER_ASK_TO_BE_FORGOTTEN = 'asktobeforgotten';
export const TRIGGER_DEAL = 'deal';
export const TRIGGER_DIDNT_REDEEM = 'didntRedeem';
export const TRIGGER_DIDNT_PURCHASE = 'didntPurchase';
export const TRIGGER_DIDNT_RECEIVE_ASSET = 'didntReceiveAsset';
export const TRIGGER_EXCEEDED_PUNCH = 'exceededPunch';
export const TRIGGER_EXTERNAL_EVENT = 'externalEvent';
export const TRIGGER_FORM_FILLED = 'formFilled';
export const TRIGGER_GIFT = 'gift';
export const TRIGGER_PROMO_CODE = 'promoCode';
export const TRIGGER_JOINED_APP = 'joinedApp';
export const TRIGGER_JOINED_CLUB = 'joinedClub';
export const TRIGGER_CLUB_MEMBER_ANNIVERSARY = 'clubMemberAnniversary';
export const TRIGGER_GAVE_CONSENT = 'gaveConsent';
export const TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE = 'joinedClubWithReferralCode';
export const TRIGGER_REFERRED_FRIEND_PURCHASE = 'referredFriendMadePurchase';
export const TRIGGER_FAILED_TO_SEND_SMS = 'failedToSendSms';
export const TRIGGER_MEMBER_HAS_ANNIVERSARY = 'anniversary';
export const TRIGGER_MEMBER_HAS_BIRTHDAY = 'birthday';
export const TRIGGER_ONE_TIME_ACTION = 'oneTimeAction';
export const TRIGGER_FILTER_MEMBERS = 'filterMember';
export const TRIGGER_ONE_TIME_CODE = 'oneTimeCode';
export const TRIGGER_SHARE_BUDGET = 'shareBudget';
export const TRIGGER_USER_CLAIMED_SHARED_BUDGET = 'userClaimedSharedBudget';
export const TRIGGER_NOTIFY_USER_SHARED_BUDGET_DONE = 'notifyUserSharedBudgetDone';
export const TRIGGER_OPENED_CLUB = 'openedClub';
export const TRIGGER_DID_NOT_OPEN_CLUB = 'didNotOpenClub';
export const TRIGGER_PAYPAL_PAYMENT_IS_DECLINED = 'payPalPaymentIsDeclined';
export const TRIGGER_PAYS_WITH_APP_CREDIT_CARD = 'paysWithAppCreditCard';
export const TRIGGER_PAYS_WITH_APP = 'paysWithApp';
export const TRIGGER_PAYS_WITH_CREDIT_AT_POS = 'paysWithCreditAtPos';
export const TRIGGER_PAYS_WITH_PAYPAL = 'paysWithPayPal';
export const TRIGGER_PAYS_WITH_POINTS_AT_POS = 'paysWithPointsAtPos';
export const TRIGGER_PUNCH = 'punch';
export const TRIGGER_PUNCH_LAST_PUNCH = 'punchLastPunch';
export const TRIGGER_PUNCH_MIDDLE_PUNCH = 'punchMiddlePunch';
export const TRIGGER_PUNCH_CARD = 'punchCard';
export const TRIGGER_PURCHASE_ANALYZED = 'purchaseAnalyzed';
export const TRIGGER_PURCHASED_ASSET = 'purchasedAsset';
export const TRIGGER_RECEIVED_ASSET = 'receivedAsset';
export const TRIGGER_RECEIVED_CREDIT = 'receivedCredit';
export const TRIGGER_RECEIVED_POINTS = 'receivedPoints';
export const TRIGGER_REDEEM = 'redeem';
export const TRIGGER_SHOPPING_CART = 'shoppingCart';
export const TRIGGER_SMS_UNSUBSCRIBE = 'smsUnsubscribe';
export const TRIGGER_EMAIL_UNSUBSCRIBE = 'emailUnsubscribe';
export const TRIGGER_START_PURCHASE = 'startPurchase';
export const TRIGGER_TAG_OPERATION = 'tagOperation';
export const TRIGGER_UNTAG_OPERATION = 'untagOperation';
export const TRIGGER_UPDATE_MEMBERSHIP = 'updateMembership';
export const TRIGGER_USED_CREDIT = 'usedCredit';
export const TRIGGER_USED_MY_REFERRAL_CODE = 'usedMyReferralCode';
export const TRIGGER_USED_POINTS = 'usedPoints';
export const TRIGGER_PUSH_FAILED = 'pushFailed';
export const TRIGGER_EMAIL_RECEIVED_SUCCESSFULLY = 'emailReceivedSuccessfully';
export const TRIGGER_EMAIL_WAS_NOT_RECEIVED = 'emailWasNotReceived';
export const TRIGGER_EMAIL_WAS_NOT_OPENED = 'emailWasNotOpened';
export const TRIGGER_TOP_UP = 'paymentAuthorized';
export const TRIGGER_MICRO_CAMPAIGN = 'microCampaign';

// UPDATE MEMBERSHIP OPERATION VALUES
export const OPERATION_VALUE_UPDATE_MEMBERSHIP = 'UpdateMembership';
export const OPERATION_VALUE_JOIN_CLUB = 'JoinClub';
export const UPDATE_MEMBERSHIP_MEMBERSHIP = 'Membership';
export const UPDATE_MEMBERSHIP_UPDATED_FIELDS = 'UpdatedFields';

export const MENTION_ID = 'id';
export const MENTION_CATEGORY = 'category';
export const MENTION_DEFAULT_SMS_LENGTH = 'smsLength';
export const DATE_MENTION_LENGTH = 10;
export const DATE_AND_TIME_MENTION_LENGTH = 21;
export const TEXT_MENTION_LENGTH = 21;
export const NUMBER_MENTION_LENGTH = 5;

export const TRUE = 'true';
export const FALSE = 'false';
export const MENTION_DISPLAY = 'display';
export const MENTIONS_MEMBERSHIP_SUB_CATEGORIES = 'mentionsMembershipSubCategories';
export const MENTIONS_CATEGORIES_ORDER = 'mentionsCategoriesOrder';
export const RESET_ALL_FILTERS = 'resetAllFilters';
export const FILTERS_NO_FILTERS_TO_SHOW = 'noFiltersShow';

export const OPERATOR_KEY_CONTAINS_NONE = 'containsNone';

// OPERATORS
export const OPERATOR_KEY_EQUALS = 'equals';
export const OPERATOR_KEY_NOT_EQUALS = 'notEquals';
export const OPERATOR_KEY_GREATER = 'greater';
export const OPERATOR_KEY_GREATER_EQUALS = 'greaterEquals';
export const OPERATOR_KEY_LESS = 'less';
export const OPERATOR_KEY_LESS_EQUALS = 'lessEquals';
export const OPERATOR_KEY_NUMBER_IS_BETWEEN = 'numberIsBetween';
export const OPERATOR_KEY_IS_ONE_OF = 'isOneOf';
export const OPERATOR_KEY_IS_NOT_ONE_OF = 'isNotOneOf';
export const OPERATOR_KEY_CONTAINS_ALL_VALUES = 'ContainsAllValues';
export const OPERATOR_KEY_STRING_CONTAINS = 'stringContains';
export const OPERATOR_KEY_STRING_NOT_CONTAINS = 'stringNotContains';
export const OPERATOR_KEY_DATE_EQUALS = 'dateEquals';
export const OPERATOR_KEY_DATE_NOT_EQUALS = 'dateNotEquals';
export const OPERATOR_KEY_DATE_IS_BEFORE = 'dateIsBefore';
export const OPERATOR_KEY_DATE_IS_AFTER = 'dateIsAfter';
export const OPERATOR_KEY_DATE_IS_ON_OR_AFTER = 'dateIsOnOrAfter';
export const OPERATOR_KEY_DATE_TIME_IS_BETWEEN = 'dateTimeIsBetween';
export const OPERATOR_KEY_DATE_IS_BETWEEN = 'dateIsBetween';
export const OPERATOR_KEY_DATE_TIME_IS_AFTER = 'dateTimeIsAfter';
export const OPERATOR_KEY_DATE_TIME_IS_BEFORE = 'dateTimeIsBefore';
export const OPERATOR_KEY_DAY_OF_WEEK = 'dayOfWeek';
export const OPERATOR_KEY_SHOPPING_CART_CONTAINS = 'shoppingCartContains';
export const OPERATOR_KEY_SHOPPING_CART_NOT_CONTAINS = 'shoppingCartNotContain';
export const OPERATOR_KEY_SHOPPING_CART_GREATER_THAN = 'shoppingCartGreaterThan';
export const OPERATOR_KEY_SHOPPING_CART_LESS_THAN = 'shoppingCartLessThan';
export const OPERATOR_KEY_SHOPPING_CART_GREATER_OR_EQUAL = 'shoppingCartGreaterOrEqual';
export const OPERATOR_KEY_SHOPPING_CART_LESS_OR_EQUAL = 'shoppingCartLessOrEqual';
export const OPERATOR_KEY_GREATER_EQUALS_VALUE = '>=';
export const OPERATOR_KEY_LESS_THAN_VALUE = '<';

export const PUNCH_CARD_REQUIRED_PUNCHES_OPTIONS = 'punchCardRequiredPunchesOptions';

// MENTIONS
export const MENTION_MEMBERSHIP_CATEGORY = 'Membership';
export const MENTION_POINTS_TRANSACTION_CATEGORY = 'Points Transaction';
export const MENTION_BUDGET_TRANSACTION_CATEGORY = 'Budget Transaction';
export const MENTION_MEMBERSHIP_BY_REG_FORM_CATEGORY = 'MembershipByRegForm';
export const MENTION_MEMBERSHIP_ADDRESS_CATEGORY = 'MembershipAddress';
export const MENTION_MEMBERSHIP_ALWAYS_SHOW_CATEGORY = 'MembershipAlwaysShow';
export const MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY = 'ExternalEvent';
export const MENTION_MEMBERSHIP_PURCHASE_CATEGORY = 'Purchase';
export const MENTION_MEMBERSHIP_JOIN_CLUB_CATEGORY = 'JoinClub';
export const MENTION_UPDATE_MEMBERSHIP_CATEGORY = 'UpdateMembership';
export const MENTION_SHARE_BUDGET_CATEGORY = 'ShareBudget';
export const MENTION_EMAIL_ACTION_CATEGORY = 'EmailAction';

export const HIDE_ON_FEATURE_DENINED = 'hideOnFeatureDenied';


// autocomplete
export const AUTO_COMPLETE_OPTIONS = 'autoCompleteOptions';
