export const GET_CONFIGURATION = 'app/oloConfiguration/GET_CONFIGURATION';
export const DELETE_CONFIGURATION = 'app/oloConfiguration/DELETE_CONFIGURATION';
export const SET_OLO_CONFIGURATION_DATA_IS_LOADING = 'app/oloConfiguration/SET_OLO_CONFIGURATION_DATA_IS_LOADING';
export const SET_OLO_CONFIGURATION_DELETE_DATA_IS_LOADING = 'app/oloConfiguration/SET_OLO_CONFIGURATION_DELETE_DATA_IS_LOADING';
export const GET_OLO_CONFIGURATION_SUCCESS = 'app/oloConfiguration/GET_OLO_CONFIGURATION_SUCCESS';
export const ON_OLO_CONFIGURATION_FIELD_CHANGE = 'app/oloConfiguration/ON_OLO_CONFIGURATION_FIELD_CHANGE';
export const ON_REDIRECT_URI_FIELD_CHANGE = 'app/oloConfiguration/ON_REDIRECT_URI_FIELD_CHANGE';
export const ON_ADD_REDIRECT_URI_FIELD = 'app/oloConfiguration/ON_ADD_REDIRECT_URI_FIELD';
export const SAVE_CONFIGURATION = 'app/oloConfiguration/SAVE_CONFIGURATION';
export const SAVING_CONFIGURATION = 'app/oloConfiguration/SAVING_CONFIGURATION';
export const CONFIGURATION_SAVE_SUCCESS = 'app/oloConfiguration/CONFIGURATION_SAVE_SUCCESS';
export const CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE = 'app/oloConfiguration/CLOSE_SAVED_CONFIGURATION_POPUP_MESSAGE';
export const DELETE_SECRET = 'app/oloConfiguration/DELETE_SECRET';
export const ERROR_OCCURRED = 'app/oloConfiguration/ERROR_OCCURRED';

// State path
export const OLO_CONFIGURATION_DATA_LOADING = 'OloConfigurationDataLoaded';
export const OLO_CONFIGURATION_DELETE_DATA_LOADING = 'OloConfigurationDeleteDataLoaded';
export const OLO_CONFIGURATION_DATA = 'data';
export const OLO_VALIDATION_ERROR = 'validationErrors';
export const OLO_CLIENT_SECRET = 'clientSecret';
export const OLO_CLIENT_NAME = 'clientName';
export const OLO_CLIENT_ID = 'clientId';
export const OLO_URL_VALIDATION_ERROR = 'olo_url_not_valid';
export const OLO_CONFIGURATION = 'oloConfiguration';
export const SAVING = 'saving';
export const SAVED_CONFIGURATION = 'savedConfiguration';
export const ERROR = 'error';


//Data
export const CONFIGURATIONS = 'configurations';
export const REDIRECT_URIS = 'redirectUris';
export const LOGO_URL = 'logoUrl';
export const ALLOW_MARKETING_COMMUNICATION_DEFAULT = 'allowMarketingCommunicationDefault';
export const CLIENT_ID = 'clientId';
export const CLIENT_SECRET = 'clientSecret';
export const CLIENT_NAME = 'clientName';
export const OLO_FIELD_REQUIRED = 'olo_field_required';
export const TERMS_OF_USE = 'termsOfUse';
export const PRIVACY_POLICY = 'privacyPolicy';
export const BASE_URL = 'oloBaseUrl';
export const APIKEY = 'apiKeyId';
export const APIKEY_DATA = 'apiKeysData';
export const APIKEY_DATA_ID = 'id';
export const APIKEY_DATA_NAME = 'name';
export const APIKEY_DATA_DEFAULT = 'default';
export const TIME_ZONE = 'businessTimeZone';
export const POINT_SHOP_WALLET = 'pointShopWallet';
export const IS_BIRTHDAY_FIELD_REQUIRED = 'isBirthdayFieldRequired';
export const MERGE_MODIFIERS_COSTS = 'mergeModifiersCosts';
