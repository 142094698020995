import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import * as constants from '../emails.constants';

class EmailStatsDetailed extends React.PureComponent {
  static propTypes = {
    statsCampaignElem: PropTypes.object,
    campIndex: PropTypes.number
  };

  render() {
    return (
      <div className="campaign-stats-row" key={`campaignRow_${this.props.campIndex.toString()}`}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6} className="campaign-stats-row-details">
              <div className="campaign-stats-column light-stats">{this.props.statsCampaignElem.get(constants.EMAIL_STATS_NAME).replaceAll('_', ' ')}</div>
            </Grid.Column>
            <Grid.Column width={10}>
              <div className="campaign-stats-column stats-line-part">{this.props.statsCampaignElem.get(constants.EMAIL_STATS_VALUE)}</div>
              { this.props.statsCampaignElem.get(constants.EMAIL_STATS_RATE) &&
              <div className="stats-line-part rate">
                <div className="campaign-stats-column light-stats rate">RATE</div>
                <div className="campaign-stats-column">{this.props.statsCampaignElem.get(constants.EMAIL_STATS_RATE)}</div>
              </div>
            }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(EmailStatsDetailed);
